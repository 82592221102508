import React, { FormEvent, FC } from 'react'
import { NewButton, isEmail, Input, ApiBaseError, NotificationType, ADMIN_PORTAL_ROLES, ROLES } from 'prace-common-components'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'constants/global'
import Grid from '@material-ui/core/Grid'
import { $Error, $Forgot, $Form, $TermsDiv } from './styles'
import { $Terms, $TermsLink } from 'components/Terms'
import { useLoginMutation } from 'store/api/auth'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'

export const Login: FC<{onClickRegister?: () => void}> = ({ onClickRegister }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const theme = useAppSelector((state: RootState) => state.organization.theme)
  const [login, { isLoading, error }] = useLoginMutation()
  const [formError, setFormError] = React.useState({ email: false, password: false })

  const handleSubmit = React.useCallback(async (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      email: { value: string },
      password: { value: string },
    }

    const newFormError = {
      email: !(target.email.value && isEmail(target.email.value)),
      password: !target.password.value,
    }
    setFormError(newFormError)
    if(!(newFormError.email || newFormError.password)) {
      try {
        const res = await login({ email: target.email.value, password: target.password.value }).unwrap()
        if([...ADMIN_PORTAL_ROLES, ROLES.SUPER_ADMIN].includes(res?.user.role))
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Please use ADMIN Portal' } })
        else navigate(Routes.APPLICATIONS)
      } catch (err) {
        console.log(err)
      }
    }
  }, [dispatch, login, navigate])

  const errorMessage = error && (error as ApiBaseError).data !== 'Unauthorized' ? 
    (error as ApiBaseError).data?.message || '' : ''

  return (
    <$Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            title='E-mail'
            name='email'
            type='email'
            required
            error={formError.email}
            errorMsg={formError.email ? 'Invalid Email' : undefined}
            hideItalic
            withAutocomplete
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            title='Password'
            name='password'
            type='password'
            required
            error={formError.password}
            errorMsg={formError.password ? 'Invalid Password' : undefined}
            hideItalic
            withAutocomplete
          />
        </Grid>
        {errorMessage &&
          <Grid item xs={12}>
            <$Error>{errorMessage}</$Error>
          </Grid>
        }
        <Grid item xs={12}>
          <NewButton fullWidth type='submit' disabled={isLoading} loading={isLoading}>
            Log in
          </NewButton>
        </Grid>
        <Grid item xs={12}>
          <NewButton fullWidth variant='outlined' onClick={onClickRegister}>
            Register
          </NewButton>
        </Grid>
        <Grid container item xs={12} justifyContent='center'>
          <$Forgot onClick={() => navigate(Routes.FORGOT_PASSWORD)}>
            Forgot password?
          </$Forgot>
        </Grid>
      </Grid>
      <$TermsDiv>
        <$Terms>
          <$TermsLink grey href={theme?.terms.link || 'https://prace-ri.eu/legal-corporate/legal-notice-terms-of-use'} target='_blank' rel='noreferrer'>Terms of Use</$TermsLink>
          <span> & </span>
          <$TermsLink grey href={theme?.privacy.link || 'https://prace-ri.eu/legal-corporate/privacy-notice'} target='_blank' rel='noreferrer'>Privacy Policy</$TermsLink>
        </$Terms>
      </$TermsDiv>
    </$Form>
  )
}
