import { Routes } from 'constants/global'
import { NotificationType } from 'prace-common-components'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useValidateMutation } from 'store/api/auth'
import { useAppDispatch } from 'store/hooks'

export const ValidateRegister: React.FC = () => {
  const dispatch = useAppDispatch()
  const [validate] = useValidateMutation()
  const { token }: { token?: string } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const validateToken = async (email: string, token: string) => {
      try {
        await validate({ email, token }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'We have confirmed your account, you can login!' } })
        navigate(Routes.LOGIN)
      } catch (err) {
        console.log(err)
      }
    }
    if (token) {
      const { email, token: registerToken } = JSON.parse(atob(token))
      if (email && registerToken) {
        validateToken(email, registerToken).catch((err) => console.log(err))
        //dispatch(AUTH_EVENTS.VALIDATE, { email, token: registerToken })
      }
    }
  }, [validate, token])

  return null
}
