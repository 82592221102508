
import sandboxConfig from '../../theme.config.json'

export const PORTAL = 'user_portal'
export const ORGANIZATION = ORGANIZATION_NAME

/* FIXME: Will be removed later */
export const HPC_DOMAIN = 'https://access.eurohpc-ju.europa.eu'
/* FIXME: Will be removed later */
export const isEuroHPC = ORGANIZATION === 'EuroHPC'

export const SHOW_CINES = HAS_CINES ? /true/.test(HAS_CINES) : false

export const __LOCAL__ = ENV === 'local'
export const __PROD__ = APP_ENV === 'production'
export const CDN = CDN_URL
export const CDN_AVATARS = `${CDN}/avatars`

export enum APPLICATION_STATUSES {
  DRAFT = 'DRAFT',
}

export enum APPLICATION_FIELDS {
  DATE = 'submissionDate',
  ID = 'uid',
  STATUS = 'status',
  CALL_TITLE = 'callTitle',
  PI_FIRST_NAME = 'firstName',
  PI_LAST_NAME = 'lastName',
  PI_NAME = 'name',
  PI_AFFILIATION = 'piAffiliation',
  RESEARCH_FIELD_GROUP = 'researchGroup',
  RESEARCH_FIELD_TITLE = 'researchTitle',
  PARTITION = 'partition',
  SUBMITTED = 'submittedAt',
}

export const config: any = sandboxConfig

export const linkFilter = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/g

export const Routes = {
  HOME: '/',
  CALLS: '/calls',
  CALL: (id: StringNumber = '') => `${Routes.CALLS}/${id}`,
  APPLICATIONS: '/applications',
  APPLICATIONS_SEARCH: (page: StringNumber = '', search = '', orderBy = '') => `${Routes.APPLICATIONS}?page=${page}&order=${orderBy}${search}`,
  APPLICATION: (uid: StringNumber = '') => `${Routes.APPLICATIONS}/${uid}`,
  AUTH: '/auth',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  REGISTER_CONFIRMATION: '/auth/register/confirmation',
  REGISTER_VERIFY: '/auth/confirm/:token',
  EMAIL_CONFIRMATION: '/auth/email/confirmation',
  EMAIL_VERIFY: '/auth/confirm/email/:token',
  FORGOT_PASSWORD: '/auth/forgot',
  FORGOT_PASSWORD_SENT: '/auth/forgot/sent',
  FORGOT_PASSWORD_RESET: '/auth/reset/:token',
  INVITED_PASSWORD: '/auth/invite/:token',
  INVITED_PASSWORD_SENT: '/auth/invite/sent',
  PROFILE: '/profile',
  PROFILE_ORGANIZATION: '/profile/organization',
  PROFILE_PASSWORD: '/profile/pwd',
  DOCUMENTATION: '/documentation',
  LEGACY: '/legacy',
  CINES: (id: StringNumber = '') => `/legacy/${id}`,
}

export const GuestRoutes = [Routes.HOME, Routes.CALLS, Routes.AUTH]

export enum STEPS {
  //Original Steps
  PROJECT_APPLICATION = 'Project Application',
  TECHNICAL_REVIEW = 'Technical Assessment',
  RAPPORTEURS = 'Rapporteurs',
  SCIENTIFIC_REVIEW = 'Scientific Evaluation',
  RESPONSE_TO_REVIEWS = 'Response to Reviews',
  INDIVIDUAL_REPORT = 'Individual Report',
  CONSOLIDATED_REPORT = 'Consolidated Report',
  RESOURCES_ALLOCATION_DECISION = 'Resources Allocation Decision',
  FINAL_STATEMENT = 'Final Statement',
  RESPONSE_TO_ALLOCATION = 'Response to Allocation',
  COMPLETED = 'Completed',
  //Benchmark Steps
  APPLICANT_SUBMISSION = 'Applicant Submission',
  FINAL_REPORT = 'Submit Final Report',
  //Development Steps
  ASSIGNING_TECHNICAL_REVIEWS = 'Assigning Technical Reviews',
  TECHNICAL_ASSESSMENT_CCR_SUPPORT = 'Technical Assessment - CCR Support',
  //EUROHPC Regular
  FINAL_FEEDBACK = 'Final Feedback',
}

export enum ELEMENTS {
  SUBMISSION_TYPE = 'Submission type',
  PROGRESS_FINAL_REPORT_PDF= 'Progress/Final Report .pdf',
}

export const NATIONALITIES = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Antiguans',
  'Argentinean',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Barbudans',
  'Batswana',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bhutanese',
  'Bolivian',
  'Bosnian',
  'Brazilian',
  'British',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comoran',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Djibouti',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirian',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Greek',
  'Grenadian',
  'Guatemalan',
  'Guinea-Bissauan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Herzegovinian',
  'Honduran',
  'Hungarian',
  'I-Kiribati',
  'Icelander',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakhstani',
  'Kenyan',
  'Kittian and Nevisian',
  'Kuwaiti',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourger',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivan',
  'Malian',
  'Maltese',
  'Marshallese',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Moroccan',
  'Mosotho',
  'Motswana',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Polish',
  'Portuguese',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Salvadoran',
  'Samoan',
  'San Marinese',
  'Sao Tomean',
  'Saudi',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Slovakian',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamer',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian or Tobagonian',
  'Tunisian',
  'Turkish',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbekistani',
  'Venezuelan',
  'Vietnamese',
  'Welsh',
  'Yemenite',
  'Zambian',
  'Zimbabwean',
]

export const TITLES = [
  'Prof.',
  'Dr.',
  'Mr.',
  'Mrs.',
  'Ms.',
  'Other',
]

export const GENDERS = [
  'Female',
  'Male',
  'Unspecified',
  'Other',
]

export const RESEARCH_FIELD_TITLES = [
  'PE1 Mathematics',
  'PE2 Fundamental Constituents of Matter',
  'PE3 Condensed Matter Physics',
  'PE4 Physical and Analytical Chemical Sciences',
  'PE5 Synthetic Chemistry and Materials',
  'PE6 Computer Science and Informatics',
  'PE7 Systems and Communication Engineering',
  'PE8 Products and Processes Engineering',
  'PE9 Universe Sciences',
  'PE10 Earth System Science',
  'LS1 Molecular and Structural Biology and Biochemistry',
  'LS2 Genetics, Genomics, Bioinformatics and Systems Biology',
  'LS3 Cellular and Developmental Biology',
  'LS4 Physiology, Pathophysiology and Endocrinology',
  'LS5 Neurosciences and Neural Disorders',
  'LS6 Immunity and Infection',
  'LS7 Diagnostic Tools, Therapies and Public Health',
  'LS8 Evolutionary, Population and Environmental Biology',
  'LS9 Applied life Sciences and Non-Medical Biotechnology',
  'SH1 Individuals, Institutions and Markets',
  'SH2 Institutions, Values, Beliefs and Behaviour',
  'SH3 Environment, Space and Population',
  'SH4 The Human Mind and Its Complexity',
  'SH5 Cultures and Cultural Production',
  'SH6 The Study of the Human Past',
]

export const RESEARCH_FIELD_SUB_TITLES = [
  'PE1_1 Logic and foundations',
  'PE1_2 Algebra',
  'PE1_3 Number theory',
  'PE1_4 Algebraic and complex geometry',
  'PE1_5 Geometry',
  'PE1_6 Topology',
  'PE1_7 Lie groups Lie algebras',
  'PE1_8 Analysis',
  'PE1_9 Operator algebras and functional analysis',
  'PE1_10 ODE and dynamical systems',
  'PE1_11 Theoretical aspects of partial differential equations',
  'PE1_12 Mathematical physics',
  'PE1_13 Probability',
  'PE1_14 Statistics',
  'PE1_15 Discrete mathematics and combinatorics',
  'PE1_16 Mathematical aspects of computer science',
  'PE1_17 Numerical analysis',
  'PE1_18 Scientific computing and data processing',
  'PE1_19 Control theory and optimization',
  'PE1_20 Application of mathematics in sciences',
  'PE1_21 Application of mathematics in industry and society life',
  'PE2_1 Fundamental interactions and fields',
  'PE2_2 Particle physics',
  'PE2_3 Nuclear physics',
  'PE2_4 Nuclear astrophysics',
  'PE2_5 Gas and plasma physics',
  'PE2_6 Electromagnetism',
  'PE2_7 Atomic molecular physics',
  'PE2_8 Ultra-cold atoms and molecules',
  'PE2_9 Optics non-linear optics and nano-optics',
  'PE2_10 Quantum optics and quantum information',
  'PE2_11 Lasers ultra-short lasers and laser physics',
  'PE2_12 Acoustics',
  'PE2_13 Relativity',
  'PE2_14 Thermodynamics',
  'PE2_15 Non-linear physics',
  'PE2_16 General physics',
  'PE2_17 Metrology and measurement',
  'PE2_18 Statistical physics (gases)',
  'PE3_1 Structure of solids and liquids',
  'PE3_2 Mechanical and acoustical properties of condensed matter',
  'PE3_3 Thermal properties of condensed matter',
  'PE3_4 Transport properties of condensed matter',
  'PE3_5 Electronic properties of materials and transport',
  'PE3_6 Lattice dynamics',
  'PE3_7 Semiconductors material growth physical properties',
  'PE3_8 Superconductivity',
  'PE3_9 Superfluids',
  'PE3_10 Spintronics',
  'PE3_11 Magnetism',
  'PE3_12 Electro-optics',
  'PE3_13 Nanophysics: nanoelectronics nanophotonics nanomagnetism',
  'PE3_14 Mesoscopic physics',
  'PE3_15 Molecular electronics',
  'PE3_16 Soft condensed matter (liquid crystals...)',
  'PE3_17 Fluid dynamics (physics)',
  'PE3_18 Statistical physics (condensed matter)',
  'PE3_19 Phase transitions phase equilibria',
  'PE3_20 Biophysics',
  'PE4_1 Physical chemistry',
  'PE4_2 Spectroscopic and spectrometric techniques',
  'PE4_3 Molecular architecture and Structure',
  'PE4_4 Surface science and nanostructures',
  'PE4_5 Analytical chemistry',
  'PE4_6 Chemical physics',
  'PE4_7 Chemical instrumentation',
  'PE4_8 Electrochemistry electrodialysis microfluidics sensors',
  'PE4_9 Method development in chemistry',
  'PE4_10 Heterogeneous catalysis',
  'PE4_11 Physical chemistry of biological systems',
  'PE4_12 Chemical reactions: mechanisms dynamics kinetics and catalytic reactions',
  'PE4_13 Theoretical and computational chemistry',
  'PE4_14 Radiation chemistry',
  'PE4_15 Nuclear chemistry',
  'PE4_16 Photochemistry',
  'PE4_17 Corrosion',
  'PE4_18 Characterization methods of materials',
  'PE5_1 Structural properties of materials',
  'PE5_2 Solid state materials',
  'PE5_3 Surface modification',
  'PE5_4 Thin films',
  'PE5_5 Ionic liquids',
  'PE5_6 New materials: oxides alloys composite organic-inorganic hybrid nanoparticles',
  'PE5_7 Biomaterials synthesis',
  'PE5_8 Intelligent materials – self assembled materials',
  'PE5_9 Environment chemistry',
  'PE5_10 Coordination chemistry',
  'PE5_11 Colloid chemistry',
  'PE5_12 Biological chemistry',
  'PE5_13 Chemistry of condensed matter',
  'PE5_14 Homogeneous catalysis',
  'PE5_15 Macromolecular chemistry',
  'PE5_16 Polymer chemistry',
  'PE5_17 Supramolecular chemistry',
  'PE5_18 Organic chemistry',
  'PE5_19 Molecular chemistry',
  'PE5_20 Combinatorial chemistry',
  'PE6_1 Computer architecture pervasive computing ubiquitous computing',
  'PE6_2 Computer systems parallel/distributed systems sensor networks embedded systems cyber- physical systems',
  'PE6_3 Software engineering operating systems computer languages',
  'PE6_4 Theoretical computer science formal methods and quantum computing',
  'PE6_5 Cryptology security privacy quantum crypto',
  'PE6_6 Algorithms distributed parallel and network algorithms algorithmic game theory',
  'PE6_7 Artificial intelligence intelligent systems multi agent systems',
  'PE6_8 Computer graphics computer vision multimedia computer games',
  'PE6_9 Human computer interaction and interface visualization and natural language processing',
  'PE6_10 Web and information systems database systems information retrieval and digital libraries',
  'PE6_11 Machine learning statistical data processing and applications using signal processing (eg. speech image and video)',
  'PE6_12 Scientific computing simulation and modelling tools',
  'PE6_13 Bioinformatics biocomputing and DNA and molecular computation',
  'PE7_1 Control engineering',
  'PE7_2 Electrical and electronic engineering: semiconductors components systems',
  'PE7_3 Simulation engineering and modelling',
  'PE7_4 Systems engineering sensorics actorics automation',
  'PE7_5 Micro- and nanoelectronics optoelectronics',
  'PE7_6 Communication technology high-frequency technology',
  'PE7_7 Signal processing',
  'PE7_8 Networks (communication networks sensor networks networks of robots.....)',
  'PE7_9 Man-machine-interfaces',
  'PE7_10 Robotics',
  'PE8_1 Aerospace engineering',
  'PE8_2 Chemical engineering technical chemistry',
  'PE8_3 Civil engineering maritime/hydraulic engineering geotechnics waste treatment',
  'PE8_4 Computational engineering',
  'PE8_5 Fluid mechanics hydraulic- turbo- and piston engines',
  'PE8_6 Energy systems (production distribution application)',
  'PE8_7 Micro (system) engineering',
  'PE8_8 Mechanical and manufacturing engineering (shaping mounting joining separation)',
  'PE8_9 Materials engineering (biomaterials metals ceramics polymers composites ...)',
  'PE8_10 Production technology process engineering',
  'PE8_11 Product design ergonomics man-machine interfaces',
  'PE8_12 Sustainable design (for recycling for environment eco-design)',
  'PE8_13 Lightweight construction textile technology',
  'PE8_14 Industrial bioengineering',
  'PE8_15 Industrial biofuel production',
  'PE9_1 Solar and interplanetary physics',
  'PE9_2 Planetary systems sciences',
  'PE9_3 Interstellar medium',
  'PE9_4 Formation of stars and planets',
  'PE9_5 Astrobiology',
  'PE9_6 Stars and stellar systems',
  'PE9_7 The Galaxy',
  'PE9_8 Formation and evolution of galaxies',
  'PE9_9 Clusters of galaxies and large scale structures',
  'PE9_10 High energy and particles astronomy – X-rays cosmic rays gamma rays neutrinos',
  'PE9_11 Relativistic astrophysics',
  'PE9_12 Dark matter dark energy',
  'PE9_13 Gravitational astronomy',
  'PE9_14 Cosmology',
  'PE9_15 Space Sciences',
  'PE9_16 Very large data bases: archiving handling and analysis',
  'PE9_17 Instrumentation - telescopes detectors and techniques',
  'PE10_1 Atmospheric chemistry atmospheric composition air pollution',
  'PE10_2 Meteorology atmospheric physics and dynamics',
  'PE10_3 Climatology and climate change',
  'PE10_4 Terrestrial ecology land cover change',
  'PE10_5 Geology tectonics volcanology',
  'PE10_6 Paleoclimatology paleoecology',
  'PE10_7 Physics of earth\'s interior seismology volcanology',
  'PE10_8 Oceanography (physical chemical biological geological)',
  'PE10_9 Biogeochemistry biogeochemical cycles environmental chemistry',
  'PE10_10 Mineralogy petrology igneous petrology metamorphic petrology',
  'PE10_11 Geochemistry crystal chemistry isotope geochemistry thermodynamics',
  'PE10_12 Sedimentology soil science palaeontology earth evolution',
  'PE10_13 Physical geography',
  'PE10_14 Earth observations from space/remote sensing',
  'PE10_15 Geomagnetism paleomagnetism',
  'PE10_16 Ozone upper atmosphere ionosphere',
  'PE10_17 Hydrology water and soil pollution',
  'LS1_1 Molecular biology and interactions',
  'LS1_2 General biochemistry and metabolism',
  'LS1_3 DNA synthesis modification repair recombination and degradation',
  'LS1_4 RNA synthesis processing modification and degradation',
  'LS1_5 Protein synthesis modification and turnover',
  'LS1_6 Biophysics',
  'LS1_7 Structural biology (crystallography NMR EM)',
  'LS1_8 Biochemistry of signal transduction',
  'LS2_1 Genomics comparative genomics functional genomics',
  'LS2_2 Transcriptomics',
  'LS2_3 Proteomics',
  'LS2_4 Metabolomics',
  'LS2_5 Glycomics',
  'LS2_6 Molecular genetics reverse genetics and RNAi',
  'LS2_7 Quantitative genetics',
  'LS2_8 Epigenetics and gene regulation',
  'LS2_9 Genetic epidemiology',
  'LS2_10 Bioinformatics',
  'LS2_11 Computational biology',
  'LS2_12 Biostatistics',
  'LS2_13 Systems biology',
  'LS2_14 Biological systems analysis modelling and simulation',
  'LS3_1 Morphology and functional imaging of cells',
  'LS3_2 Cell biology and molecular transport mechanisms',
  'LS3_3 Cell cycle and division',
  'LS3_4 Apoptosis',
  'LS3_5 Cell differentiation physiology and dynamics',
  'LS3_6 Organelle biology',
  'LS3_7 Cell signalling and cellular interactions',
  'LS3_8 Signal transduction',
  'LS3_9 Development developmental genetics pattern formation and embryology in animals',
  'LS3_10 Development developmental genetics pattern formation and embryology in plants',
  'LS3_11 Cell genetics',
  'LS3_12 Stem cell biology',
  'LS4_1 Organ physiology',
  'LS4_2 Comparative physiology',
  'LS4_3 Endocrinology',
  'LS4_4 Ageing',
  'LS4_5 Metabolism biological basis of metabolism related disorders',
  'LS4_6 Cancer and its biological basis',
  'LS4_7 Cardiovascular diseases',
  'LS4_8 Non-communicable diseases (except for neural/psychiatric immunity-related metabolism- related disorders cancer and cardiovascular diseases)',
  'LS5_1 Neuroanatomy and neurophysiology',
  'LS5_2 Molecular and cellular neuroscience',
  'LS5_3 Neurochemistry and neuropharmacology',
  'LS5_4 Sensory systems (e.g. visual system auditory system)',
  'LS5_5 Mechanisms of pain',
  'LS5_6 Developmental neurobiology',
  'LS5_7 Cognition (e.g. learning memory emotions speech)',
  'LS5_8 Behavioral neuroscience (e.g. sleep consciousness handedness)',
  'LS5_9 Systems neuroscience',
  'LS5_10 Neuroimaging and computational neuroscience',
  'LS5_11 Neurological disorders (e.g. Alzheimer\'s disease Huntington\'s disease Parkinson\'s disease)',
  'LS6_1 Innate immunity',
  'LS6_2 Adaptive immunity',
  'LS6_3 Phagocytosis and cellular immunity',
  'LS6_4 Immunosignalling',
  'LS6_5 Immunological memory and tolerance',
  'LS6_6 Immunogenetics',
  'LS6_7 Microbiology',
  'LS6_8 Virology',
  'LS6_9 Bacteriology',
  'LS6_10 Parasitology',
  'LS6_11 Prevention and treatment of infection by pathogens (e.g. vaccination antibiotics fungicide)',
  'LS6_12 Biological basis of immunity related disorders',
  'LS6_13 Veterinary medicine',
  'LS7_1 Medical engineering and technology',
  'LS7_2 Diagnostic tools (e.g. genetic imaging)',
  'LS7_3 Pharmacology pharmacogenomics drug discovery and design drug therapy',
  'LS7_4 Analgesia',
  'LS7_5 Toxicology',
  'LS7_6 Gene therapy stem cell therapy regenerative medicine',
  'LS7_7 Surgery',
  'LS7_8 Radiation therapy',
  'LS7_9 Health services health care research',
  'LS7_10 Public health and epidemiology',
  'LS7_11 Environment and health risks including radiation',
  'LS7_12 Occupational medicine',
  'LS7_13 Medical ethics',
  'LS8_1 Ecology (theoretical community population microbial evolutionary ecology)',
  'LS8_2 Population biology population dynamics population genetics plant-animal interactions',
  'LS8_3 Systems evolution biological adaptation phylogenetics systematics',
  'LS8_4 Biodiversity comparative biology',
  'LS8_5 Conservation biology ecology genetics',
  'LS8_6 Biogeography',
  'LS8_7 Animal behaviour (behavioural ecology animal communication)',
  'LS8_8 Environmental and marine biology',
  'LS8_9 Environmental toxicology',
  'LS8_10 Prokaryotic biology',
  'LS8_11 Symbiosis',
  'LS9_1 Genetic engineering transgenic organisms recombinant proteins biosensors',
  'LS9_2 Synthetic biology and new bio-engineering concepts',
  'LS9_3 Agriculture related to animal husbandry dairying livestock raising',
  'LS9_4 Aquaculture fisheries',
  'LS9_5 Agriculture related to crop production soil biology and cultivation applied plant biology',
  'LS9_6 Food sciences',
  'LS9_7 Forestry biomass production (e.g. for biofuels)',
  'LS9_8 Environmental biotechnology bioremediation biodegradation',
  'LS9_9 Biotechnology (non-medical) bioreactors applied microbiology',
  'LS9_10 Biomimetics',
  'LS9_11 Biohazards biological containment biosafety biosecurity',
  'SH1_1 Macroeconomics business cycles',
  'SH1_2 Development economic growth',
  'SH1_3 Microeconomics institutional economics',
  'SH1_4 Econometrics statistical methods',
  'SH1_5 Financial markets asset prices international finance',
  'SH1_7 Competitiveness innovation research and development',
  'SH1_8 Consumer choice behavioural economics marketing',
  'SH1_9 Organization studies strategy',
  'SH1_10 Human resource management labour economics',
  'SH1_11 Public economics political economics public administration',
  'SH1_12 Income distribution poverty',
  'SH1_13 International trade economic geography',
  'SH1_14 History of economics and economic thought quantitative and institutional economic history',
  'SH2_1 Social structure inequalities social mobility interethnic relations',
  'SH2_2 Ageing work social policies welfare',
  'SH2_3 Kinship cultural dimensions of classification and cognition identity gender',
  'SH2_4 Myth ritual symbolic representations religious studies',
  'SH2_5 Democratization social movements',
  'SH2_6 Violence conflict and conflict resolution',
  'SH2_7 Political systems and institutions governance',
  'SH2_8 Legal theory legal systems constitutions comparative law',
  'SH2_9 Global and transnational governance international studies human rights',
  'SH2_10 Communication networks media information society',
  'SH2_11 Social studies of science and technology science technology and innovation policies',
  'SH3_1 Environment resources and sustainability',
  'SH3_2 Environmental change and society',
  'SH3_3 Environmental regulations and climate negotiations',
  'SH3_4 Social and industrial ecology',
  'SH3_5 Population dynamics health and society',
  'SH3_6 Families and households',
  'SH3_7 Migration',
  'SH3_8 Mobility tourism transportation and logistics',
  'SH3_9 Spatial development land use regional planning',
  'SH3_10 Urbanization cities and rural areas',
  'SH3_11 Infrastructure human and political geography settlements',
  'SH3_12 Geo-information and spatial data analysis',
  'SH4_1 Evolution of mind and cognitive functions animal communication',
  'SH4_2 Human life-span development',
  'SH4_3 Neuropsychology and clinical psychology',
  'SH4_4 Cognitive and experimental psychology: perception action and higher cognitive processes',
  'SH4_5 Linguistics: formal cognitive functional and computational linguistics',
  'SH4_6 Linguistics: typological historical and comparative linguistics',
  'SH4_7 Psycholinguistics and neurolinguistics: acquisition and knowledge of language language pathologies',
  'SH4_8 Use of language: pragmatics sociolinguistics discourse analysis second language teaching and learning lexicography terminology',
  'SH4_9 Philosophy history of philosophy',
  'SH4_10 Epistemology logic philosophy of science',
  'SH4_11 Ethics and morality bioethics',
  'SH4_12 Education: systems and institutions teaching and learning',
  'SH5_1 Classics ancient Greek and Latin literature and art',
  'SH5_2 History of literature',
  'SH5_3 Literary theory and comparative literature literary styles',
  'SH5_4 Textual philology and palaeography',
  'SH5_5 Visual arts',
  'SH5_6 Performing arts',
  'SH5_7 Museums and exhibitions',
  'SH5_8 Music and musicology history of music',
  'SH5_9 History of art and history of architecture',
  'SH5_10 Cultural studies cultural diversity',
  'SH5_11 Cultural heritage cultural memory',
  'SH6_1 Archaeology archaeometry landscape archaeology',
  'SH6_2 Prehistory and protohistory',
  'SH6_3 Ancient history',
  'SH6_4 Medieval history',
  'SH6_5 Early modern history',
  'SH6_6 Modern and contemporary history',
  'SH6_7 Colonial and post-colonial history global and transnational history',
  'SH6_8 Social and economic history',
  'SH6_9 History of ideas intellectual history history of sciences and techniques',
  'SH6_10 Cultural history',
  'SH6_11 History of collective identities and memories history of gender',
  'SH6_12 Historiography theory and methods of history',
]

export const RESEARCH_FIELD_GROUPS = {
  'Biochemistry, Bioinformatics and Life sciences': ['LS1', 'LS2', 'LS8', 'LS9'],
  'Chemical Sciences and Materials': ['PE3', 'PE4', 'PE5'],
  'Earth System Sciences': ['PE10'],
  'Economics, Finance and Management': ['SH1', 'SH2'],
  Engineering: ['PE7', 'PE8'],
  'Fundamental Constituents of Matter': ['PE2'],
  'Linguistics, Cognition and Culture': ['SH3', 'SH4', 'SH5', 'SH6'],
  'Mathematics and Computer Sciences': ['PE1', 'PE6'],
  'Physiology and Medicine': ['LS3', 'LS4', 'LS5', 'LS6', 'LS7'],
  'Universe Science': ['PE9'],
}

export enum ReviewApproveState {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  REQUEST_CHANGES = 'request_changes',
}
