import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const $GridItem = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(1)};
`

export const $ModalContainer = styled.div`
  width: 500px;
`

export const $TemporaryText = styled.p`
  margin-bottom: 8px;
  margin-top: 0px;
  color:  ${({ theme }) => theme.colors.icons.visibility};
`
