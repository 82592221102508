/* eslint-disable no-param-reassign */
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { authApi } from 'store/api/auth'
import { AuthState } from './types'
import { profileApi } from 'store/api/profile'
import { ADMIN_PORTAL_ROLES, ROLES } from 'prace-common-components'

const initialState: AuthState = {
  token: '',
  user: undefined,
  isAuth: !!window.localStorage.getItem('usr'),
}

const refresh = createAction<{ token: string }, 'refresh'>('refresh')

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refresh, (state, { payload }) => {
      state.token = payload.token
    })
    builder.addMatcher(isAnyOf(
      authApi.endpoints.login.matchFulfilled,
      authApi.endpoints.refresh.matchFulfilled,
    ),(state, { payload }) => {
      const { user, token } = payload
      if (![...ADMIN_PORTAL_ROLES, ROLES.SUPER_ADMIN].includes(user.role)) {
        window.localStorage.setItem('usr', 'true')
        state.token = token
        if((state.user?.id !== user?.id) || (state.user?.role !== user?.role)) {
          // update user info for error reporting
          Sentry.setUser({ id: String(user.id), role: user.role })
          state.user = user
        }
        if(!state.isAuth) state.isAuth = true
      }
    })
    builder.addMatcher(profileApi.endpoints.updateProfile.matchFulfilled,(state, {payload}) => {
      if(!!payload && payload.role && (payload.role !== state.user?.role)) {
        state.user = { id: Number(state.user?.id), role: payload.role }
      }
    })
    builder.addMatcher(authApi.endpoints.validate.matchFulfilled,(state) => {
      state.token = initialState.token
      state.user = initialState.user
      state.isAuth = false
    })
    builder.addMatcher(authApi.endpoints.forgot.matchFulfilled,(state) => {
      state.token = initialState.token
      state.user = initialState.user
    })
    builder.addMatcher(authApi.endpoints.reset.matchFulfilled, (state) => {
      state.token = initialState.token
      state.user = initialState.user
      state.isAuth = false
    })
    builder.addMatcher(isAnyOf(
      authApi.endpoints.logout.matchFulfilled,
      authApi.endpoints.confirmEmail.matchFulfilled,
    ), (state) => {
      window.localStorage.removeItem('usr')
      // clear user info for error reporting
      Sentry.configureScope((scope) => scope.setUser(null))
      state.token = initialState.token
    })
  },
})

export const authReducer = slice.reducer
