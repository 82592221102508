import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { activeTimeFrame, replaceURLs, NotFound404, FlowCall, CallCard, Title, NewButton, NewModal, NotificationType } from 'prace-common-components'
import { CDN_AVATARS, Routes } from 'constants/global'
import {
  $PageTitle,
  $OtherCalls,
  $CallDetails,
  $Description,
  $Buttons,
  $CardStatus,
  $StatusColor,
  $StatusText,
  $CallImage,
} from './styles'
import Grid from '@material-ui/core/Grid'
import { Banner } from 'components/Banner'
import { ApplyModal } from 'components/ApplyModal'
import { useCountAppliedQuery, useGetCallCutOffRoleQuery, useGetCallsQuery } from 'store/api/calls'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useLazyDownloadApplicationReportQuery, useLazyDownloadCallBundleQuery } from 'store/api/downloads'
import { useCreateApplicationMutation } from 'store/api/applications'
import { getCallStatus } from 'util/getCallStatus'

export const CallDetails = () => {
  const { id = '0' } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userId = useAppSelector((state: RootState) => state.auth.user?.id)
  const isAuth = useAppSelector((state: RootState) => !!state.auth.token)
  const [createApplication, { isLoading: createLoading }] = useCreateApplicationMutation()
  const calls = useAppSelector((state: RootState) => state.calls?.data || [])
  //TODO: If CallDetails is a child of Home, we would not need to put getCalls here
  const { isLoading, isSuccess, isError } = useGetCallsQuery(undefined, { skip: calls.length > 1 })
  const { data: currentCalCutoffRole } = useGetCallCutOffRoleQuery(Number(id), { skip: !id || !isAuth })
  const currentCall = calls.find((call) => call.id == Number(id))
  const user = { id: userId, role: currentCalCutoffRole?.role }
  const [downloadCallBundle] = useLazyDownloadCallBundleQuery()
  const [downloadReport] = useLazyDownloadApplicationReportQuery()
  const [applyOpen, setApplyOpen] = React.useState(false)

  const otherCalls = calls?.filter((call) => call.id != Number(id)) || []
  const initials = currentCall?.title.slice(0, 2).toLocaleUpperCase() || ''
  const title = currentCall?.title || ''
  const description = currentCall?.description || ''
  const info = currentCall?.info || ''
  const status = currentCall ? getCallStatus(currentCall) : false
  const btnLabel = isAuth ? 'Apply to Call' : 'Register to apply'
  const allowedToDownload = currentCall?.allowedToDownload
  const callId = currentCall?.id || 0

  const loading = createLoading || isLoading
  
  const { data: countAppliedData = { total: 0, submitted: 0 } } =
    useCountAppliedQuery(callId, { skip: !callId || !isAuth || !user })
  
  const applyToCall = async () => {
    if (currentCall) {
      try {
        const payload = await createApplication({ callId: currentCall.id }).unwrap()
        setApplyOpen(false)
        navigate(Routes.APPLICATION(payload.uid))
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleDownloadBundleClick = (id: FlowCall['id']) => async () => {
    try {
      await downloadCallBundle(id).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const handleDownloadApplicationReport = (id: FlowCall['id'], format: 'csv' | 'xlsx') => async () => {
    try {
      await downloadReport({ id, format }).unwrap()
      dispatch({ type: 'notification', payload: { timer: 5000, type: NotificationType.success, msg: 'The applications report will be sent to your email address. This could take up to 5 minutes.' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleCardClick = (_idx: number, callId: FlowCall['id']) => () => navigate(Routes.CALL(callId))

  if((isSuccess || isError) && !currentCall) return <NotFound404 message='The call you’re looking for couldn’t be found' />
  if(!currentCall) return null

  const onApplyClick = () => {
    if(!isAuth) {
      navigate(Routes.REGISTER)
    } else {
      const { total = 0, submitted = 0 } = countAppliedData
      if(total && (total > submitted)) setApplyOpen(true)
      else applyToCall()
    }
  }

  return (
    <>
      <Banner>
        <Grid container alignItems='center'>
          <Grid item>
            <$CallImage src={currentCall.logo ? `${CDN_AVATARS}/${currentCall.logo}` : undefined}>
              {currentCall.logo ? '' : currentCall.title.slice(0, 2).toLocaleUpperCase()}
            </$CallImage>
          </Grid>
          <Grid item>
            <$PageTitle>{title}</$PageTitle>
            <$CardStatus> {/* TODO: Should be a common component */}
              <$StatusColor open={status} />
              <$StatusText>{status ? 'Open' : 'Closed'}</$StatusText>
            </$CardStatus>
          </Grid>
          <$Buttons item xs={12}>
            <NewButton
              onClick={onApplyClick}
              data-test-id='apply-call-top-button'
              disabled={!status}
              loading={loading}
            >
              {btnLabel}
            </NewButton>
          </$Buttons>
        </Grid>
      </Banner>
      <$CallDetails container>
        <Grid item xs={12} sm={6}>
          <Title alternate>Call Details</Title>
          <$Description>
            {description}
          </$Description>
        </Grid>
        {(allowedToDownload?.length || info) &&
          <Grid item xs={12} sm={6}>
            {info && <Title alternate>Call Process</Title>}
            <$Description dangerouslySetInnerHTML={{ __html: replaceURLs(info) }}>
            </$Description>
            {user && currentCalCutoffRole?.canDownloadBundle &&
              <$Buttons container>
                <NewButton variant='outlined' onClick={handleDownloadBundleClick(currentCall.id)}>
                  Download Bundle
                </NewButton>
                <NewButton variant='outlined' onClick={handleDownloadApplicationReport(currentCall.id, 'csv')}>
                  Download Call Report
                </NewButton>
              </$Buttons>}
          </Grid>
        }
      </$CallDetails>
      <$OtherCalls container>
        <Title alternate>Other Calls</Title>
        <Grid container item xs={12} spacing={3}>
          {otherCalls.map((call: FlowCall, idx: number) => <Grid item key={`PrevCard-${call.id}`}>
            <CallCard
              logoSrc={call.logo ? `${CDN_AVATARS}/${call.logo}` : undefined}
              initials={initials}
              title={call.title}
              description={call.description}
              open={getCallStatus(call)}
              onClick={handleCardClick(idx, call.id)}
              timeframe={activeTimeFrame(call.flow.find((step) => step.order === 0))}
            />
          </Grid>,
          )}
        </Grid>
      </$OtherCalls>
      {applyOpen &&
        <NewModal open alternateTitle title='Apply to Call' onClose={() => setApplyOpen(false)}>
          <ApplyModal
            handleApply={() => applyToCall()}
            handleDashboard={() => navigate(Routes.APPLICATIONS)}
            handleCancel={() => setApplyOpen(false)}
          />
        </NewModal>
      }
    </>
  )
}
