import React, { FC, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { isValidPassword, Input, Landing as LandingContainer, NewButton, NotificationType } from 'prace-common-components'
import { CDN, Routes } from 'constants/global'
import { useResetMutation } from 'store/api/auth'
import { useAppDispatch } from 'store/hooks'

export const InvitedUser: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [resetPass] = useResetMutation()
  const params: { token?: string } = useParams()
  const [state, setState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    token: '',
  })

  useEffect(() => {
    if (params.token) {
      const { email, token } = JSON.parse(atob(params.token))
      if (token) setState((state) => ({...state, email, token}))
      return
    }
  }, [params.token])

  const errors = {
    password: state.password !== '' && !isValidPassword(state.password),
    confirmPassword: !!state.password.length && state.password !== state.confirmPassword,
  }

  const handleInputChange = (name: string, value: Nullable<StringNumber>) => {
    setState((state) => ({...state, [name]: value }))
  }

  const handleSavePassword = async () => {
    if(state.email && state.password) {
      try {
        await resetPass({ email: state.email, password: state.password, token: state.token }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Password saved, you can login!' } })
        navigate(Routes.LOGIN)
      } catch (err) {
        console.log(err)
      }
    }
  }

  return <LandingContainer logo={`${CDN}/logo.png`} image={`${CDN}/forgotPasswordSend.jpg`} title='Choose a Password' description='Enter a password for your new account. Use the toggle show/hide to validate.'>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          title='E-mail Address'
          name='email'
          type='email'
          required
          disabled
          value={state.email}
          hideItalic
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          title='Password'
          placeholder='Password'
          name='password'
          type='password'
          required
          error={errors.password}
          errorMsg={errors.password ? 'Password must have one lowercase, one uppercase, one symbol and be at least 12 characters long.' : undefined}
          value={state.password}
          onChange={handleInputChange}
          hideItalic
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          required
          title='Confirm password'
          placeholder='Password'
          name='confirmPassword'
          type='password'
          error={errors.confirmPassword}
          errorMsg={errors.confirmPassword ? 'Passwords mismatch.' : undefined}
          value={state.confirmPassword}
          onChange={handleInputChange}
          hideItalic
        />
      </Grid>
      <Grid item xs={12}>
        <NewButton fullWidth onClick={handleSavePassword}>
        Save Password
        </NewButton>
      </Grid>
      <Grid item xs={12}>
        <NewButton variant='outlined' fullWidth onClick={() => navigate(-1)}>
        Back
        </NewButton>
      </Grid>
    </Grid>
  </LandingContainer>
}
